import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';

const AuthGuard = ({ children }) => {
    const { isAuthenticated } = useAuth();
    const location = useLocation();

    sessionStorage.setItem('loginRedirectPath', location.pathname);

    if (!isAuthenticated) {
        return <Redirect to="/" />;
    }

    return <>{children}</>;
};

AuthGuard.propTypes = {
    children: PropTypes.node,
};

export default AuthGuard;
