import React, { createContext, useEffect, useReducer, useState } from 'react';
import useAuth from '../hooks/useAuth';
import { API, graphqlOperation } from 'aws-amplify';
import { listCognitoGroups } from '../graphql/queries';

const initialGroupState = {
    groups: null,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'GROUPS': {
            return {
                ...state,
                groups: action.payload.groups,
                //selectors: action.payload.selectors,
            };
        }
        default: {
            return { ...state };
        }
    }
};

const CognitoGroupContext = createContext({
    ...initialGroupState,
    method: 'Groups',
    getAllGroups: () => {},
    renewGroups: () => {},
});

export const GroupsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialGroupState);
    // const [reloadGroups] = useState(false);
    const { user } = useAuth();

    const getAllGroups = async () => {
        if (!user.isAuthenticated) {
            return null;
        }
        const groups = await API.graphql(
            graphqlOperation(listCognitoGroups, {
                userPoolId: process.env.REACT_APP_USERPOOL_ID,
            })
        );
        return groups.data.listCognitoGroups.slice(1, -1).split(',');
    };

    useEffect(() => {
        const initData = async () => {
            try {
                const groups = await getAllGroups();
                if (groups) {
                    dispatch({
                        type: 'GROUPS',
                        payload: {
                            groups,
                        },
                    });
                }
            } catch (err) {
                console.error(err);
            }
        };
        initData();
    }, []);

    const renewGroups = () => {};
    return (
        <CognitoGroupContext.Provider
            value={{
                ...state,
                getAllGroups,
                renewGroups,
            }}
        >
            {children}
        </CognitoGroupContext.Provider>
    );
};

export default CognitoGroupContext;
