import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Label from './Label';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
const useStyles = makeStyles((theme = useTheme()) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        minHeight: '100%',
        padding: theme.spacing(3),
        textAlign: 'center',
    },
}));

const LoadingScreen = (props) => {
    const classes = props.styles ?? useStyles();

    useEffect(() => {
        NProgress.start();

        return () => {
            NProgress.done();
        };
    }, []);

    return (
        <div className={classes.root}>
            <Box width={400}>
                <LinearProgress />
                <Typography
                    variant="body1"
                    sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                    {props.message}
                </Typography>
            </Box>
        </div>
    );
};

export default LoadingScreen;
