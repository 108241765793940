import React from 'react';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import useAuth from '../../hooks/useAuth';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    avatar: {
        height: 32,
        width: 32,
    },
}));

const UserAvatar = ({ className }) => {
    const classes = useStyles();
    const { user } = useAuth();

    return (
        <Avatar
            alt="User"
            className={clsx(classes.avatar, className)}
            src={user.user === null ? null : user.user.photo}
        />
    );
};

export default UserAvatar;
