import { API, graphqlOperation } from 'aws-amplify';
import { addDailyActivity } from '../graphql/queries';

function setActivity(route, type, message = null, payload = null) {
    try {
        API.graphql(
            graphqlOperation(addDailyActivity, {
                route: route,
                type,
                message,
                payload,
            })
        );
    } catch (error) {
        console.log('error', error);
    }
}

export default setActivity;
