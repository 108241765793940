import React, { createContext, useEffect, useReducer } from 'react';
import SplashScreen from '../components/SplashScreen';
import { API, Auth, Cache, graphqlOperation } from 'aws-amplify';
import { getUser, initUser, getUserByEmail } from '../graphql/queries';
import { createUser } from '../graphql/mutations';
import { onUpdateUser } from '../graphql/subscriptions';

const initialAuthState = {
    isAuthenticated: false,
    inGroupManager: false,
    isInitialised: false,
    user: null,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'INITIALISE': {
            const { isAuthenticated } = action.payload;
            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user: action.payload,
            };
        }
        case 'REFRESH': {
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload,
            };
        }
        case 'LOGIN': {
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload,
            };
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            };
        }
        default: {
            return { ...state };
        }
    }
};

const AuthContext = createContext({
    ...initialAuthState,
    method: 'SSO',
    loginWithSSO: () => {},
    logout: () => {},
});

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);

    useEffect(() => {
        const initialise = async () => {
            try {
                const isAuthenticated = await Auth.currentAuthenticatedUser();

                if (isAuthenticated) {
                    var user;
                    var email =
                        isAuthenticated.signInUserSession.idToken.payload.email.toLowerCase() ||
                        '';
                    var req = await API.graphql(
                        graphqlOperation(getUserByEmail, {
                            email: email,
                        })
                    );
                    user = req?.data?.getUserByEmail.items[0];
                    if (!user) {
                        //initialize user with default roles
                        const params = {
                            roles: JSON.stringify({
                                role: ['Default'],
                                additionalRoles: ['Dashboard Viewer'],
                            }),
                            coreId: isAuthenticated.signInUserSession.idToken.payload[
                                'custom:COREID'
                            ].toLowerCase(),
                            email: email,
                            firstName:
                                isAuthenticated.signInUserSession.idToken
                                    .payload.given_name,
                            surname:
                                isAuthenticated.signInUserSession.idToken
                                    .payload.family_name,
                            name:
                                isAuthenticated.signInUserSession.idToken
                                    .payload.given_name +
                                ' ' +
                                isAuthenticated.signInUserSession.idToken
                                    .payload.family_name,
                            cognitoId:
                                isAuthenticated.signInUserSession.idToken
                                    .payload.sub,
                        };
                        await API.graphql(
                            graphqlOperation(createUser, { input: params })
                        );
                    }

                    req = await API.graphql(
                        graphqlOperation(getUserByEmail, {
                            email: email,
                        })
                    );
                    user = req?.data?.getUserByEmail.items[0];

                    if (
                        user?.cognitoId === undefined ||
                        !user?.cognitoId ||
                        !user.cognitoId
                    ) {
                        // Need to call the lambda resolver for initialiseUser
                        const response = await API.graphql(
                            graphqlOperation(initUser, {
                                email: email,
                                sub: isAuthenticated.signInUserSession.idToken
                                    .payload.sub,
                                userId: user?.id,
                                userPoolId: process.env.REACT_APP_USERPOOL_ID,
                            })
                        );
                        user = {
                            ...user,
                            ...JSON.parse(response.data.initUser),
                        };
                    }

                    user.token =
                        isAuthenticated.signInUserSession.accessToken.jwtToken;
                    // these values will be returned every page refresh
                    dispatch({
                        type: 'INITIALISE',
                        payload: {
                            isAuthenticated: true,
                            user,
                            cognito_username:
                                isAuthenticated['signInUserSession']['idToken'][
                                    'payload'
                                ]['cognito:username'],
                        },
                    });
                } else {
                    dispatch({
                        type: 'INITIALISE',
                        payload: {
                            isAuthenticated,
                            user: null,
                        },
                    });
                }
            } catch (err) {
                //console.error(err);
                dispatch({
                    type: 'INITIALISE',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        };
        initialise();

        const interval = setInterval(async () => {
            const isAuthenticated = await Auth.currentAuthenticatedUser();
            if (isAuthenticated) {
                const activeSession = await Auth.currentSession();

                var user;
                const req = await API.graphql(
                    graphqlOperation(getUserByEmail, {
                        email: isAuthenticated.signInUserSession.idToken.payload.email.toLowerCase(),
                    })
                );
                user = req?.data?.getUserByEmail.items[0];

                const token = activeSession.accessToken.jwtToken;

                dispatch({
                    type: 'REFRESH',
                    payload: {
                        isAuthenticated: true,
                        user: {
                            ...user,
                            token: token,
                            changed: 'yes',
                            cognito_username:
                                isAuthenticated['signInUserSession']['idToken'][
                                    'payload'
                                ]['cognito:username'],
                        },
                    },
                });
            }
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const getUpdate = API.graphql(graphqlOperation(onUpdateUser)).subscribe(
            {
                next: (data) => {
                    const initialise = async () => {
                        try {
                            //const activeSession =
                            await Auth.currentSession();
                            const isAuthenticated =
                                await Auth.currentAuthenticatedUser();
                            const {
                                value: {
                                    data: { onUpdateUser },
                                },
                            } = data;
                            if (
                                onUpdateUser.cognitoId ===
                                isAuthenticated.signInUserSession.accessToken
                                    .payload.sub
                            ) {
                                const req = await API.graphql(
                                    graphqlOperation(getUser, {
                                        id: onUpdateUser.id,
                                    })
                                );
                                const user = req?.data?.getUser;

                                dispatch({
                                    type: 'REFRESH',
                                    payload: {
                                        isAuthenticated: true,
                                        user: {
                                            ...user,
                                        },
                                    },
                                });
                            }
                        } catch (err) {
                            console.error(err);
                        }
                    };
                    initialise();
                },
            }
        );
        return () => getUpdate.unsubscribe();
    }, []);

    const loginWithSSO = async (options) => {
        try {
            await Auth.federatedSignIn({ provider: 'OKTA' });
        } catch (error) {
            console.log('There was an error signing in to the app', error);
        }
    };

    const logout = async () => {
        try {
            Cache.removeItem('prbs');
            localStorage.clear();
            await Auth.signOut();
        } catch (error) {
            console.log('There was an error signing out of the app', error);
        }

        dispatch({
            type: 'LOGOUT',
        });
    };

    if (!state.isInitialised) {
        return <SplashScreen />;
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'SSO',
                loginWithSSO,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
