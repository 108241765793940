import React from 'react';
import SecondarySidebarToggle from './SecondarySidebarToggle';
// import SecondarySidenavTheme from '../../../theme/SecondarySidenavTheme';

const SecondarySidebar = () => {
    return (
        // <SecondarySidenavTheme>
        <SecondarySidebarToggle />
        // </SecondarySidenavTheme>
    );
};

export default SecondarySidebar;
