import React from 'react';
import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import { FullscreenRounded } from '@mui/icons-material';

const FullscreenMode = ({ fullscreen, setFullscreen }) => {
    return (
        <>
            <Tooltip title="Fullscreen Mode">
                <IconButton
                    color="inherit"
                    onClick={() => setFullscreen(!fullscreen)}
                >
                    <SvgIcon fontSize="small">
                        <FullscreenRounded />
                    </SvgIcon>
                </IconButton>
            </Tooltip>
        </>
    );
};

export default FullscreenMode;
