import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import NavBar from './NavBar';
import TopBar from './TopBar';
import SecondarySidebar from './SecondarySidebar';

const SidebarLayout = ({ children }) => {
    //const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const [isNavOpen, setNavOpen] = useState(true);
    const [navSize, setNavSize] = useState(290);

    useEffect(() => {
        if (isNavOpen) {
            setNavSize(290);
        } else {
            setNavSize(0);
        }
    }, [isNavOpen]);

    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.background.default,
            display: 'flex',
            minHeight: '100%',
            overflow: 'hidden',
            width: '100%',
        },
        wrapper: {
            display: 'flex',
            flex: '1 1 auto',
            overflow: 'hidden',
            paddingTop: 64,
            [theme.breakpoints.up('lg')]: {
                paddingLeft: navSize,
            },
        },
        contentContainer: {
            display: 'flex',
            flex: '1 1 auto',
            overflow: 'hidden',
        },
        content: {
            flex: '1 1 auto',
            height: '100%',
            overflow: 'auto',
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
            '&::webkit-scrollbar': {
                display: 'none',
            },
        },
    }));

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <TopBar
                /* onMobileNavOpen={() => setMobileNavOpen(true)}
                openMobile={isMobileNavOpen} */
                onNavOpen={() => setNavOpen(!isNavOpen)}
                openNav={isNavOpen}
            />
            <NavBar
                /* onMobileClose={() => setMobileNavOpen(false)}
                openMobile={isMobileNavOpen} */
                openNav={isNavOpen}
            />
            <SecondarySidebar />
            <div className={classes.wrapper}>
                <div className={classes.contentContainer}>
                    <div className={classes.content}>{children}</div>
                </div>
            </div>
        </div>
    );
};

SidebarLayout.propTypes = {
    children: PropTypes.node,
};

export default SidebarLayout;
