/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onChannelMessage = /* GraphQL */ `
  subscription OnChannelMessage($userId: String!) {
    onChannelMessage(userId: $userId) {
      id
      message
      userId
      type
      createdAt
      requestId
      ProgressPercent
      updatedAt
      __typename
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onCreateMessage(filter: $filter) {
      id
      message
      userId
      type
      createdAt
      requestId
      ProgressPercent
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onUpdateMessage(filter: $filter) {
      id
      message
      userId
      type
      createdAt
      requestId
      ProgressPercent
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage($filter: ModelSubscriptionMessageFilterInput) {
    onDeleteMessage(filter: $filter) {
      id
      message
      userId
      type
      createdAt
      requestId
      ProgressPercent
      updatedAt
      __typename
    }
  }
`;
export const onCreateQueryGPT = /* GraphQL */ `
  subscription OnCreateQueryGPT($filter: ModelSubscriptionQueryGPTFilterInput) {
    onCreateQueryGPT(filter: $filter) {
      id
      user
      query
      response
      sources
      feedbackBool
      feedbackText
      feedbackBy
      createdAt
      origin
      updatedAt
      __typename
    }
  }
`;
export const onUpdateQueryGPT = /* GraphQL */ `
  subscription OnUpdateQueryGPT($filter: ModelSubscriptionQueryGPTFilterInput) {
    onUpdateQueryGPT(filter: $filter) {
      id
      user
      query
      response
      sources
      feedbackBool
      feedbackText
      feedbackBy
      createdAt
      origin
      updatedAt
      __typename
    }
  }
`;
export const onDeleteQueryGPT = /* GraphQL */ `
  subscription OnDeleteQueryGPT($filter: ModelSubscriptionQueryGPTFilterInput) {
    onDeleteQueryGPT(filter: $filter) {
      id
      user
      query
      response
      sources
      feedbackBool
      feedbackText
      feedbackBy
      createdAt
      origin
      updatedAt
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      cognitoId
      email
      name
      coreId
      displayName
      firstName
      surname
      jobTitle
      officeLocation
      roles
      photo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      cognitoId
      email
      name
      coreId
      displayName
      firstName
      surname
      jobTitle
      officeLocation
      roles
      photo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      cognitoId
      email
      name
      coreId
      displayName
      firstName
      surname
      jobTitle
      officeLocation
      roles
      photo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDailyActivity = /* GraphQL */ `
  subscription OnCreateDailyActivity(
    $filter: ModelSubscriptionDailyActivityFilterInput
  ) {
    onCreateDailyActivity(filter: $filter) {
      id
      route
      users
      payload
      type
      message
      hasPayload
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDailyActivity = /* GraphQL */ `
  subscription OnUpdateDailyActivity(
    $filter: ModelSubscriptionDailyActivityFilterInput
  ) {
    onUpdateDailyActivity(filter: $filter) {
      id
      route
      users
      payload
      type
      message
      hasPayload
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDailyActivity = /* GraphQL */ `
  subscription OnDeleteDailyActivity(
    $filter: ModelSubscriptionDailyActivityFilterInput
  ) {
    onDeleteDailyActivity(filter: $filter) {
      id
      route
      users
      payload
      type
      message
      hasPayload
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateActivityPayload = /* GraphQL */ `
  subscription OnCreateActivityPayload(
    $filter: ModelSubscriptionActivityPayloadFilterInput
  ) {
    onCreateActivityPayload(filter: $filter) {
      id
      payload
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateActivityPayload = /* GraphQL */ `
  subscription OnUpdateActivityPayload(
    $filter: ModelSubscriptionActivityPayloadFilterInput
  ) {
    onUpdateActivityPayload(filter: $filter) {
      id
      payload
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteActivityPayload = /* GraphQL */ `
  subscription OnDeleteActivityPayload(
    $filter: ModelSubscriptionActivityPayloadFilterInput
  ) {
    onDeleteActivityPayload(filter: $filter) {
      id
      payload
      createdAt
      updatedAt
      __typename
    }
  }
`;
