import React from 'react';
import PropTypes from 'prop-types';
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Tooltip,
    Toolbar,
    SvgIcon,
    useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MenuRounded, MenuOpenRounded } from '@mui/icons-material';
import Account from './Account';
import DarkMode from './DarkMode';
import { THEMES } from '../../../constants';

const TopBar = ({
    className,
    onMobileNavOpen,
    onNavOpen,
    openNav,
    ...rest
}) => {
    const barWidth = useMediaQuery('(min-width:1200px)')
        ? openNav
            ? 'calc(100% - 290px)'
            : '100%'
        : '100%';

    const useStyles = makeStyles((theme) => ({
        barRoot: {
            zIndex: theme.zIndex.drawer + 100,
            width: barWidth + ' !important',
            ...(theme.name === THEMES.LIGHT
                ? {
                      //boxShadow: 'none',
                      backgroundColor:
                          theme.palette.background.defaultTransparent +
                          ' !important',
                      color: theme.palette.text.secondary + ' !important',
                  }
                : {}),
            ...(theme.name === THEMES.DARK
                ? {
                      backgroundColor:
                          theme.palette.background.defaultTransparent +
                          ' !important',
                      color: theme.palette.text.secondary + ' !important',
                  }
                : {}),
        },
        toolbar: {
            minHeight: 64,
            paddingLeft: '0.5%',
        },
    }));
    const classes = useStyles();

    return (
        <AppBar elevation={0} className={classes.barRoot} {...rest}>
            <Toolbar className={classes.toolbar}>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onMobileNavOpen}>
                        <SvgIcon fontSize="small">
                            <MenuRounded />
                        </SvgIcon>
                    </IconButton>
                </Hidden>
                <Hidden lgDown>
                    <Tooltip title={openNav ? 'Close Sidebar' : 'Open Sidebar'}>
                        <IconButton color="inherit" onClick={onNavOpen}>
                            <SvgIcon fontSize="small">
                                {openNav ? (
                                    <MenuOpenRounded />
                                ) : (
                                    <MenuRounded />
                                )}
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                </Hidden>
                {/* <Search /> */}
                <Box ml={2} flexGrow={1} />
                {/* <Notifications /> */}
                <DarkMode />
                {/* <Settings /> */}
                <Box ml={2}>
                    <Account />
                </Box>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func,
    onNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
    onMobileNavOpen: () => {},
    onNavOpen: () => {},
};

export default TopBar;
