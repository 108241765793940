import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, ButtonBase, Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useAuth from '../../../hooks/useAuth';
import UserAvatar from '../../../components/Avatars/UserAvatar';

const useStyles = makeStyles((theme) => ({
    popover: {
        width: 200,
    },
}));

const Account = () => {
    const classes = useStyles();
    const history = useHistory();
    const ref = useRef(null);
    const { logout } = useAuth();
    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = async () => {
        try {
            handleClose();
            await logout();
            history.push('/');
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                component={ButtonBase}
                onClick={handleOpen}
                ref={ref}
            >
                <UserAvatar />
            </Box>
            <Menu
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                PaperProps={{ className: classes.popover }}
                keepMounted
                // className={classes.popover}
                // getContentAnchorEl={null}
                anchorEl={ref.current}
                open={isOpen}
            >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </>
    );
};

export default Account;
