export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
    LIGHT: 'LIGHT',
    DARK: 'DARK',
};

export const NOTIFICATION_TYPES = {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    PROGRESS: 'progress',
};

export const ACTIVITY_TYPES = {
    ROUTE: 'route',
    REQUEST: 'request',
    ERROR: 'error',
    SUCCESS: 'success',
    DLLICENSE: 'download_license',
    SOURCEURL: 'open_source_url',
    REQUESTGENAI: 'request_genai',
};

export const DOMAINS = [
    'evm.online',
    'ews.online',
    'evs.online',
    'videomanager.online',
    'anz.videomanager.online',
    'dev.videomanager.exc.motorolasolutions.com',
    'videomanager.ex.motorolasolutions.com',
    'videomanager.exc.motorolasolutions.com',
];

export const INSTANCETYPE = ['t3.medium', 'c5.large'];
export const GRAFANAURL =
    'https://grafana.videomanager.online/d/f158560d-4908-4f38-8e14-d0f71b1d352f/cloudops-support?orgId=1&refresh=5s&var-datasource=GRAFANADATASOURCE&var-region=REGION';
export const GRAFANAGOVURL =
    'https://grafana-usgov.videomanager.ex.motorolasolutions.com/d/f158560d-4908-4f38-8e14-d0f71b1d352f/cloudops-support?orgId=1&refresh=5s&var-datasource=GRAFANADATASOURCE&var-region=REGION';
export const ENDPOINT =
    process.env.REACT_APP_AWS_BRANCH === 'main' ||
    process.env.REACT_APP_AWS_BRANCH === 'staging'
        ? 'https://api.evm.online'
        : process.env.NODE_ENV === 'development'
        ? ''
        : 'https://api-dev.evm.online';

export const ENDPOINT_RMA_API =
    process.env.REACT_APP_AWS_BRANCH === 'main' ||
    process.env.REACT_APP_AWS_BRANCH === 'staging'
        ? 'https://iv9bvwazj0.execute-api.eu-west-2.amazonaws.com/Prod'
        : process.env.NODE_ENV === 'development'
        ? 'https://319cuib3e1.execute-api.eu-west-2.amazonaws.com/Prod'
        : 'https://319cuib3e1.execute-api.eu-west-2.amazonaws.com/Prod';

export const RMA_STATUS = [
    {
        label: 'All',
        value: 0,
    },
    {
        label: 'Authorized',
        value: 101,
    },
    {
        label: 'Received & Assessing',
        value: 121,
    },
    {
        label: 'Payment Requested',
        value: 122,
    },
    {
        label: 'In Repair',
        value: 123,
    },
    {
        label: 'Pending Invoice',
        value: 126,
    },
    {
        label: 'RMA Complete',
        value: 131,
    },
    {
        label: 'RMA Cancelled',
        value: 141,
    },
];

export const TIER_OPTIONS = [
    {
        label: 'Tier 1 - Complex customers (2+3)',
        value: 1,
    },
    {
        label: 'Tier 2 - Needs customer notification',
        value: 2,
    },
    {
        label: 'Tier 3 - Can receive upgrades',
        value: 3,
    },
];

export const INSTANCE_STATE = [
    {
        label: 'Running',
        value: 'running',
    },
    {
        label: 'Stopped',
        value: 'stopped',
    },
];

export const GENERIC_AI_MODEL = [
    {
        label: 'ChatGPT 4',
        value: 'ChatGPT4',
        dataSetId: '',
    },

    {
        label: 'Dall E-3',
        value: 'MsiITDallE',
        dataSetId: '',
    },
    {
        label: 'Gemini 1.5 Flash (BETA)',
        value: 'VertexGemini',
        dataSetId: '',
    },
    {
        label: 'Claude Sonnet',
        value: 'Claude-Sonnet',
        dataSetId: '',
    },
];

export const VMEX_AI_MODEL = [
    {
        label: 'VMEX GenAI',
        value: 'VMEXGenAI',
        dataSetId: '',
    },
    // {
    //     label: 'VMEX-Cloud-Incidents',
    //     value: 'VertexGemini',
    //     dataSetId: '81488c8c-e540-4a13-aecc-eacfbf4a40bc',
    // },
];
