import React from 'react';
import { Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useAuth from '../../hooks/useAuth';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    avatar: {
        cursor: 'pointer',
        width: theme.spacing(6),
        height: theme.spacing(6),
        borderColor: theme.palette.actions.success,
        borderStyle: 'solid',
        animation: `$avatarEffect 3000ms ${theme.transitions.easing.easeInOut}`,
        animationIterationCount: 'infinite',
    },
    '@keyframes avatarEffect': {
        '0%': {
            borderStyle: theme.palette.actions.success,
        },
        '50%': {
            borderColor: 'transparent',
        },
        '100%': {
            borderColor: theme.palette.actions.success,
        },
    },
}));

const AnimatedUserAvatar = ({ className, component, to }) => {
    const classes = useStyles();
    const { user } = useAuth();

    return (
        <Avatar
            alt="User"
            className={clsx(classes.avatar, className)}
            src={user.user === null ? null : user.user.photo}
            component={component}
            to={to}
        />
    );
};

export default AnimatedUserAvatar;
