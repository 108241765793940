/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(({ palette, ...theme }) => ({
    toggle: {
        position: 'fixed',
        right: '30px',
        bottom: '50px',
        zIndex: 99,
        transition: 'all 0.15s ease',
        '&.open': {
            right: '10px',
        },
    },
}));

const SecondarySidebarToggle = () => {
    const classes = useStyles();

    return (
        <div
            className={clsx({
                [classes.toggle]: true,
            })}
        >
            {/* <Fab
        color="primary"
        aria-label="expand"
        className=""
        //onClick={toggle}
      >
        <Icon>settings</Icon>
      </Fab> */}
        </div>
    );
};

export default SecondarySidebarToggle;
