import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import TopBar from './TopBar/index';
import useFullscreen from '../../hooks/useFullscreen';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        minHeight: '100%',
        overflow: 'hidden',
        width: '100%',
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
        // [theme.breakpoints.up("lg")]: {
        //   paddingLeft: 10,
        // },
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto',
        backgroundColor: theme.palette.background.default,
    },
}));

const SidebarLayout = ({ children }) => {
    const classes = useStyles();
    //const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const picRef = useRef(null);
    const [full, setFull] = useFullscreen(picRef);

    return (
        <div className={classes.root}>
            {full ? (
                <></>
            ) : (
                <TopBar
                    //onMobileNavOpen={() => setMobileNavOpen(true)}
                    //openMobile={isMobileNavOpen}
                    setFullscreen={setFull}
                    fullscreen={full}
                />
            )}
            <div className={classes.wrapper}>
                <div
                    className={classes.contentContainer}
                    /* class={full ? 'fullscreen' : ''} */
                    ref={picRef}
                >
                    <div className={classes.content}>{children}</div>
                </div>
            </div>
        </div>
    );
};

SidebarLayout.propTypes = {
    children: PropTypes.node,
};

export default SidebarLayout;
