import React, { useState, useEffect } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, Collapse, ListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'block',
        paddingTop: 0,
        paddingBottom: 0,
    },
    itemLeaf: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    button: {
        color: theme.palette.text.secondary,
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'capitalize',
        letterSpacing: 0,
        width: '100%',
        '&:active': {
            color: theme.palette.secondary.main,
            '& $title': {
                fontWeight: theme.typography.fontWeightMedium,
            },
            '& $icon': {
                color: theme.palette.secondary.main,
            },
        },
    },
    buttonLeaf: {
        color: theme.palette.text.secondary,
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'capitalize',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightRegular,
        '&.depth-0': {
            '& $title': {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        color: theme.palette.text.secondary,
    },
    title: {
        marginRight: 'auto',
        textTransform: 'capitalize',
        color: theme.palette.text.secondary,
    },
    /* active: {
        color: theme.palette.secondary.main,
        '& $title': {
            fontWeight: theme.typography.fontWeightMedium,
        },
        '& $icon': {
            color: theme.palette.secondary.main,
        },
    }, */
}));

const NavItem = ({
    children,
    className,
    depth,
    href,
    icon: Icon,
    info: Info,
    open,
    title,
    ...rest
}) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(open);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    let paddingLeft = 8;

    if (depth > 0) {
        paddingLeft = 32 + 8 * depth;
    }

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const style = { paddingLeft };

    if (children) {
        return (
            <ListItem
                className={clsx(classes.item, className)}
                disableGutters
                key={title}
                {...rest}
            >
                <Button
                    className={classes.button}
                    //activeClassName={classes.active}
                    onClick={() => handleToggle()}
                    style={style}
                >
                    {Icon && <Icon className={classes.icon} size="20" />}
                    <span className={classes.title}>{title}</span>
                    {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
                <Collapse in={isOpen} timeout="auto" unmountOnExit={true}>
                    {children}
                </Collapse>
            </ListItem>
        );
    }

    return (
        <ListItem
            className={clsx(classes.itemLeaf, className)}
            disableGutters
            key={title}
            {...rest}
        >
            <Button
                activeClassName={classes.active}
                className={clsx(classes.buttonLeaf, `depth-${depth}`)}
                component={RouterLink}
                exact
                style={style}
                to={href}
            >
                {Icon && <Icon className={classes.icon} size="20" />}
                <span className={classes.title}>{title}</span>
                {Info && <Info />}
            </Button>
        </ListItem>
    );
};

NavItem.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    depth: PropTypes.number.isRequired,
    href: PropTypes.string,
    icon: PropTypes.elementType,
    info: PropTypes.elementType,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
};

export default NavItem;
