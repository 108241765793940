import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AppBar, Box, Toolbar } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { THEMES } from '../../../constants';
import DarkLogo from '../../../cloud-ops-logo_dark_2x.png';
import LightLogo from '../../../cloud-ops-logo_light_2x.png';
import Account from './Account';
import DarkMode from './DarkMode';
import FullscreenMode from './Fullscreen';

const TopBar = ({
    className,
    onMobileNavOpen,
    fullscreen,
    setFullscreen,
    ...rest
}) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            zIndex: theme.zIndex.drawer + 100,
            backgroundColor: theme.palette.background.defaultTransparent,
            color: theme.palette.text.secondary,
        },
        toolbar: {
            maxHeight: 64,
            paddingLeft: '0.5%',
            backgroundColor: theme.palette.background.defaultTransparent,
            color: theme.palette.text.secondary,
        },
    }));

    const classes = useStyles();

    return (
        <AppBar
            elevation={0}
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Toolbar className={classes.toolbar}>
                <RouterLink to="/home">
                    <Box p={2} display="flex" justifyContent="center">
                        <img
                            src={
                                useTheme().name === THEMES.LIGHT
                                    ? LightLogo
                                    : DarkLogo
                            }
                            alt="Video Manager EX Cloud Ops Portal"
                            width="70px"
                        />
                    </Box>
                </RouterLink>
                <Box ml={2} flexGrow={1} />
                <FullscreenMode
                    setFullscreen={setFullscreen}
                    fullscreen={fullscreen}
                />
                <DarkMode />
                <Box ml={2}>
                    <Account />
                </Box>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
    onMobileNavOpen: () => {},
};

export default TopBar;
