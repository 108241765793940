import React from 'react';
import StandardCard from '../Cards/StandardCard';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import Lock from '@material-ui/icons/Lock';
import Page from './Page';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
    },
    cardContainer: {
        paddingBottom: 80,
        paddingTop: 80,
    },
    cardContent: {
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
    },
}));

const AccessDeniedPage = () => {
    const classes = useStyles();

    const cardContent = (
        <Box alignItems="center" display="flex" justifyContent="center">
            <div>
                <div align="center">
                    <Lock fontSize="large" />
                </div>
                <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h2"
                    align="center"
                >
                    Access Denied
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    You do not have the permissions to access this page
                </Typography>
            </div>
        </Box>
    );

    return (
        <Page className={classes.root} title="Access Denied">
            <Container className={classes.cardContainer} maxWidth="sm">
                <StandardCard
                    cardContent={cardContent}
                    className={classes.cardContent}
                />
            </Container>
        </Page>
    );
};

export default AccessDeniedPage;
