import { Amplify } from 'aws-amplify';
import config from './aws-exports';
require('dotenv').config();
export function getUrlBase() {
    let signInUrl;
    var signOutUrl;

    if (process.env.REACT_APP_AWS_BRANCH === 'main') {
        signInUrl = 'https://portal.evm.online';
        signOutUrl = 'https://portal.evm.online';
    } else if (process.env.REACT_APP_AWS_BRANCH === 'staging') {
        signInUrl = 'https://portal-staging.evm.online';
        signOutUrl = 'https://portal-staging.evm.online';
    } else {
        signInUrl = 'https://portal-dev.evm.online';
        signOutUrl = 'https://portal-dev.evm.online';
    }

    if (process.env.NODE_ENV === 'development') {
        signInUrl = 'http://localhost';
        signOutUrl = 'http://localhost';
    }
    return signInUrl;
}
export function configureAmplify() {
    var signInUrl;
    var signOutUrl;

    if (process.env.REACT_APP_AWS_BRANCH === 'main') {
        signInUrl = 'https://portal.evm.online/';
        signOutUrl = 'https://portal.evm.online/';
    } else if (process.env.REACT_APP_AWS_BRANCH === 'staging') {
        signInUrl = 'https://portal-staging.evm.online/';
        signOutUrl = 'https://portal-staging.evm.online/';
    } else {
        signInUrl = 'https://portal-dev.evm.online/';
        signOutUrl = 'https://portal-dev.evm.online/';
    }

    if (process.env.NODE_ENV === 'development') {
        signInUrl = 'http://localhost:3000';
        signOutUrl = 'http://localhost:3000';
    }

    const options = {
        ...config,
        userPoolId: process.env.REACT_APP_USERPOOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID,
        oauth: {
            domain: process.env.REACT_APP_DOMAIN,
            scope: ['openid'],
            redirectSignIn: signInUrl,
            redirectSignOut: signOutUrl,
            responseType: 'code',
        },
        storage: localStorage,
    };

    Amplify.configure(options);

    //localStorage.clear();
}
