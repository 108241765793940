export const ADD_ON_ROLES = {
    createCustomer: 'can-create-customer',
    updateCustomer: 'can-update-customer',
    viewCustomer: 'can-view-customer',
    manageCustomer: 'can-manage-customer',
    createEnvironment: 'can-create-environment',
    manageEnvironment: 'can-manage-environment',
    createLicense: 'can-create-license',
    updateLicense: 'can-update-license',
    manageLicense: 'can-manage-license',
    deleteLicense: 'can-delete-license',
    viewLicense: 'can-view-license',
    grafanaAdmin: 'admin-grafana',
    viewVideoManager: 'can-view-vmex',
    manageVideoManager: 'can-manage-vmex',
    uploadPackageVideoManager: 'can-uploadpackage-vmex',
    upgradeVideoManager: 'can-upgrade-vmex',
    viewUsers: 'can-view-users',
    updateUsers: 'can-update-users',
    manageUsers: 'can-manage-users',
    viewActivity: 'can-view-activity',
    manageSpreadsheet: 'can-manage-spreadsheet-productcodes',
    upgradeInternalVideoManager: 'can-upgradeinternal-vmex',
};

export const ADD_ON_SW_ROLES = {
    managerRMA: 'sw-can-manage-rma',
    viewRMAReport: 'sw-can-view-rma-report',
    createRMA: 'sw-can-create-rma',
};

export const ADD_ON_AI_ROLES = {
    manageGenAi: 'can-manage-genai',
    queryGenericModelsAi: 'can-querygenericmodels-genai',
    queryVMEXModelAi: 'can-queryvmexmodel-genai',
    queryVMEXCloudIncidents: 'can-queryvmexcloudincidents-genai',
};

export const BASE_ROLES = {
    default: 'Default',
    restrictedUser: 'Restricted User',
    cloudopsUser: 'CloudOps User',
    cloudopsManager: 'CloudOps Manager',
    admin: 'Admin',
};

export const ROLES = {
    ...BASE_ROLES,
    ...ADD_ON_ROLES,
    ...ADD_ON_SW_ROLES,
};

export const ADD_ON_ROLES_SCOPES = {
    dashboardCanView: 'can-view-dashboard',
};

export const BASE_ROLE_SCOPES = {
    restrictedCanView: 'restricted-can-view',
    ...ADD_ON_ROLES,
    ...ADD_ON_SW_ROLES,
    ...ADD_ON_AI_ROLES,
    adminAll: 'admin-all',
};

export const SCOPES_SW = {
    ...ADD_ON_SW_ROLES,
};

export const SCOPES_AI = {
    ...ADD_ON_AI_ROLES,
};

export const SCOPES = {
    ...BASE_ROLE_SCOPES,
    ...ADD_ON_ROLES_SCOPES,
};

const ROLES_CUSTOMERS = {
    [SCOPES.viewCustomer]: [SCOPES.viewCustomer],
    [SCOPES.createCustomer]: [SCOPES.viewCustomer, SCOPES.createCustomer],
    [SCOPES.updateCustomer]: [SCOPES.viewCustomer, SCOPES.updateCustomer],
    [SCOPES.manageCustomer]: [
        SCOPES.viewCustomer,
        SCOPES.createCustomer,
        SCOPES.updateCustomer,
        SCOPES.manageCustomer,
    ],
};

const ROLES_LICENSE = {
    [SCOPES.viewLicense]: [SCOPES.viewLicense],
    [SCOPES.createLicense]: [SCOPES.createLicense],
    [SCOPES.deleteLicense]: [SCOPES.deleteLicense],
    [SCOPES.manageLicense]: [
        SCOPES.viewLicense,
        SCOPES.createLicense,
        SCOPES.manageLicense,
    ],
};

const ROLES_ENVIRONMENT = {
    [SCOPES.createEnvironment]: [SCOPES.createEnvironment],
    [SCOPES.manageEnvironment]: [
        SCOPES.createEnvironment,
        SCOPES.manageEnvironment,
    ],
};

const ROLES_VM = {
    [SCOPES.viewVideoManager]: [SCOPES.viewVideoManager],
    [SCOPES.upgradeVideoManager]: [
        SCOPES.viewVideoManager,
        SCOPES.upgradeVideoManager,
        SCOPES.uploadPackageVideoManager,
    ],

    [SCOPES.upgradeInternalVideoManager]: [
        SCOPES.viewVideoManager,
        SCOPES.upgradeInternalVideoManager,
        SCOPES.uploadPackageVideoManager,
    ],
    [SCOPES.manageVideoManager]: [
        SCOPES.viewVideoManager,
        SCOPES.manageVideoManager,
        SCOPES.upgradeVideoManager,
        SCOPES.upgradeInternalVideoManager,
        SCOPES.uploadPackageVideoManager,
    ],
    [SCOPES.uploadPackageVideoManager]: [
        SCOPES.viewVideoManager,
        SCOPES.uploadPackageVideoManager,
    ],
};

const ROLES_USERS = {
    [SCOPES.viewUsers]: [SCOPES.viewUsers],
    [SCOPES.updateUsers]: [SCOPES.updateUsers],
    [SCOPES.manageUsers]: [
        SCOPES.viewUsers,
        SCOPES.updateUsers,
        SCOPES.manageUsers,
    ],
};

const ROLES_GRAFANA = {
    [SCOPES.grafanaViewer]: [SCOPES.grafanaViewer],
    [SCOPES.grafanaAdmin]: [SCOPES.grafanaAdmin, SCOPES.grafanaViewer],
};

const ROLES_SPREADSHEET = {
    [SCOPES.manageSpreadsheet]: [SCOPES.manageSpreadsheet],
};

const ROLES_ACTIVITY = {
    [SCOPES.viewActivity]: [SCOPES.viewActivity],
};

const ROLES_SW = {
    [SCOPES_SW.viewRMAReport]: [SCOPES.viewRMAReport],
    [SCOPES_SW.createRMA]: [SCOPES.createRMA, SCOPES.viewRMAReport],
    [SCOPES_SW.managerRMA]: [
        SCOPES.managerRMA,
        SCOPES.createRMA,
        SCOPES.viewRMAReport,
    ],
};

const ROLES_AI = {
    [SCOPES_AI.manageGenAi]: [
        SCOPES.manageGenAi,
        SCOPES.queryGenericModelsAi,
        SCOPES.queryVMEXModelAi,
    ],
    [SCOPES_AI.queryGenericModelsAi]: [SCOPES.queryGenericModelsAi],
    [SCOPES_AI.queryVMEXModelAi]: [
        SCOPES.queryGenericModelsAi,
        SCOPES.queryVMEXModelAi,
    ],
    [SCOPES_AI.queryVMEXCloudIncidents]: [SCOPES.queryVMEXCloudIncidents],
};

export const ADD_ON_ROLE_PERMISSIONS = {
    [ROLES.default]: [SCOPES.dashboardCanView],
    ...ROLES_CUSTOMERS,
    ...ROLES_LICENSE,
    ...ROLES_ENVIRONMENT,
    ...ROLES_VM,
    ...ROLES_USERS,
    ...ROLES_GRAFANA,
    ...ROLES_SPREADSHEET,
    ...ROLES_ACTIVITY,
    ...ROLES_SW,
    ...ROLES_AI,
};

const DEFAULT_PERMISSIONS = [];

const RESTRICTED_USER_PERMISSIONS = [
    ...Object.values(ADD_ON_ROLES),
    ...Object.values(ADD_ON_SW_ROLES),
    ...Object.values(ADD_ON_AI_ROLES),
    SCOPES.restrictedCanView,
];

const CLOUDOPS_USER_PERMISSIONS = [...RESTRICTED_USER_PERMISSIONS];

const CLOUDOPS_MANAGER_PERMISSIONS = [
    ...CLOUDOPS_USER_PERMISSIONS,
    SCOPES.restrictedCanView,
    SCOPES.createCustomer,
    SCOPES.updateCustomer,
    SCOPES.createEnvironment,
    SCOPES.createLicense,
    SCOPES.updateLicense,
];

export const BASE_ROLE_PERMISSIONS = {
    [ROLES.default]: DEFAULT_PERMISSIONS,
    [ROLES.restrictedUser]: RESTRICTED_USER_PERMISSIONS,
    [ROLES.cloudopsUser]: CLOUDOPS_USER_PERMISSIONS,
    [ROLES.cloudopsManager]: CLOUDOPS_MANAGER_PERMISSIONS,
    [ROLES.admin]: Object.values(SCOPES),
};

export const PERMISSIONS = {
    ...BASE_ROLE_PERMISSIONS,
    ...ADD_ON_ROLE_PERMISSIONS,
};
