/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      message
      userId
      type
      createdAt
      requestId
      ProgressPercent
      updatedAt
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      message
      userId
      type
      createdAt
      requestId
      ProgressPercent
      updatedAt
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      message
      userId
      type
      createdAt
      requestId
      ProgressPercent
      updatedAt
      __typename
    }
  }
`;
export const createQueryGPT = /* GraphQL */ `
  mutation CreateQueryGPT(
    $input: CreateQueryGPTInput!
    $condition: ModelQueryGPTConditionInput
  ) {
    createQueryGPT(input: $input, condition: $condition) {
      id
      user
      query
      response
      sources
      feedbackBool
      feedbackText
      feedbackBy
      createdAt
      origin
      updatedAt
      __typename
    }
  }
`;
export const updateQueryGPT = /* GraphQL */ `
  mutation UpdateQueryGPT(
    $input: UpdateQueryGPTInput!
    $condition: ModelQueryGPTConditionInput
  ) {
    updateQueryGPT(input: $input, condition: $condition) {
      id
      user
      query
      response
      sources
      feedbackBool
      feedbackText
      feedbackBy
      createdAt
      origin
      updatedAt
      __typename
    }
  }
`;
export const deleteQueryGPT = /* GraphQL */ `
  mutation DeleteQueryGPT(
    $input: DeleteQueryGPTInput!
    $condition: ModelQueryGPTConditionInput
  ) {
    deleteQueryGPT(input: $input, condition: $condition) {
      id
      user
      query
      response
      sources
      feedbackBool
      feedbackText
      feedbackBy
      createdAt
      origin
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      cognitoId
      email
      name
      coreId
      displayName
      firstName
      surname
      jobTitle
      officeLocation
      roles
      photo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      cognitoId
      email
      name
      coreId
      displayName
      firstName
      surname
      jobTitle
      officeLocation
      roles
      photo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      cognitoId
      email
      name
      coreId
      displayName
      firstName
      surname
      jobTitle
      officeLocation
      roles
      photo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDailyActivity = /* GraphQL */ `
  mutation CreateDailyActivity(
    $input: CreateDailyActivityInput!
    $condition: ModelDailyActivityConditionInput
  ) {
    createDailyActivity(input: $input, condition: $condition) {
      id
      route
      users
      payload
      type
      message
      hasPayload
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDailyActivity = /* GraphQL */ `
  mutation UpdateDailyActivity(
    $input: UpdateDailyActivityInput!
    $condition: ModelDailyActivityConditionInput
  ) {
    updateDailyActivity(input: $input, condition: $condition) {
      id
      route
      users
      payload
      type
      message
      hasPayload
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDailyActivity = /* GraphQL */ `
  mutation DeleteDailyActivity(
    $input: DeleteDailyActivityInput!
    $condition: ModelDailyActivityConditionInput
  ) {
    deleteDailyActivity(input: $input, condition: $condition) {
      id
      route
      users
      payload
      type
      message
      hasPayload
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createActivityPayload = /* GraphQL */ `
  mutation CreateActivityPayload(
    $input: CreateActivityPayloadInput!
    $condition: ModelActivityPayloadConditionInput
  ) {
    createActivityPayload(input: $input, condition: $condition) {
      id
      payload
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateActivityPayload = /* GraphQL */ `
  mutation UpdateActivityPayload(
    $input: UpdateActivityPayloadInput!
    $condition: ModelActivityPayloadConditionInput
  ) {
    updateActivityPayload(input: $input, condition: $condition) {
      id
      payload
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteActivityPayload = /* GraphQL */ `
  mutation DeleteActivityPayload(
    $input: DeleteActivityPayloadInput!
    $condition: ModelActivityPayloadConditionInput
  ) {
    deleteActivityPayload(input: $input, condition: $condition) {
      id
      payload
      createdAt
      updatedAt
      __typename
    }
  }
`;
