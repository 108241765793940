import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';

const GuestGuard = ({ children }) => {
    const { isAuthenticated, user } = useAuth();
    if (isAuthenticated) {
        var path = '/home';
        if (user?.user?.roles !== undefined) {
            var roles = JSON.parse(JSON.parse(JSON.stringify(user.user.roles)));
            if (roles.role === 'Default') path = '/dashboards/serviceHealth';
        }
        const redirectLocation = sessionStorage.getItem('loginRedirectPath');
        if (redirectLocation === null || redirectLocation === undefined) {
            return <Redirect to={path} />;
        }
        return <Redirect to={redirectLocation} />;
    }

    return <>{children}</>;
};

GuestGuard.propTypes = {
    children: PropTypes.node,
};

export default GuestGuard;
