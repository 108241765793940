import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'nprogress/nprogress.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { enableES5 } from 'immer';
import * as serviceWorker from '../src/serviceWorker';
import { SettingsProvider } from '../src/contexts/SettingsContext';
import App from '../src/App';

enableES5();

ReactDOM.render(
    <SettingsProvider>
        <App />
    </SettingsProvider>,
    document.getElementById('root')
);

serviceWorker.register();
