import React, { useRef } from 'react';
import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import { THEMES } from '../../../constants';
import {
    Brightness2Rounded as LightModeIcon,
    WbSunnyRounded as DarkModeIcon,
} from '@mui/icons-material';

const DarkMode = () => {
    const ref = useRef(null);
    const { settings, saveSettings } = useSettings();

    const handleChange = (theme) => {
        saveSettings({ theme: theme });
    };

    return (
        <>
            <Tooltip title="Dark Mode">
                <IconButton
                    color="inherit"
                    onClick={
                        settings.theme === THEMES.LIGHT
                            ? () => handleChange(THEMES.DARK)
                            : () => handleChange(THEMES.LIGHT)
                    }
                    ref={ref}
                >
                    <SvgIcon fontSize="small">
                        {settings.theme === THEMES.LIGHT ? (
                            <LightModeIcon />
                        ) : (
                            <DarkModeIcon />
                        )}
                    </SvgIcon>
                </IconButton>
            </Tooltip>
        </>
    );
};

export default DarkMode;
