import { CardActions, CardHeader, Card, CardContent } from '@mui/material';
import * as React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    card: {
        // Use flex layout with column direction for components in the card
        // (CardContent and CardActions)
        display: 'flex',
        flexDirection: 'column',

        // Justify the content so that CardContent will always be at the top of the card,
        // and CardActions will be at the bottom
        //justifyContent: "space-between",
        padding: '16px',
    },
}));

const StandardCard = ({
    cardContent,
    cardActions,
    title,
    elevation,
    className,
    onClickCard,
    ...rest
}) => {
    const classes = useStyles();

    return (
        <Card
            elevation={elevation ? elevation : 3}
            className={clsx(className, classes.card)}
            onClick={onClickCard}
            style={{
                borderRadius: '35px',
                display: 'flex',
                justifyContent: 'center',
            }}
            {...rest}
        >
            {title ? (
                <CardHeader
                    title={title}
                    titleTypographyProps={{
                        variant: 'h3',
                        color: 'textPrimary',
                    }}
                />
            ) : (
                <React.Fragment />
            )}
            <CardContent>{cardContent}</CardContent>
            {cardActions ? (
                <CardActions>{cardActions}</CardActions>
            ) : (
                <React.Fragment />
            )}
        </Card>
    );
};

export default StandardCard;
