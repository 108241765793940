import React, { createRef } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SWRConfig } from 'swr';
// import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { Collapse, IconButton } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider, jssPreset } from '@mui/styles';
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from './components/GlobalStyles';
import ScrollReset from './components/ScrollReset';
import { AuthProvider } from './contexts/SSOContext';
import { CustomerProvider } from './contexts/CustomerContext';
import useSettings from './hooks/useSettings';
import { createTheme } from './theme';
import routes, { renderRoutes } from './routes';
import { configureAmplify } from './configureAmplify';
import { CloseRounded } from '@mui/icons-material';
import { GroupsProvider } from './contexts/CognitoGroupsContext';
import { useEffect } from 'react';
import setActivity from './utils/setActivity';
import { ACTIVITY_TYPES } from './constants';

require('dotenv').config();

configureAmplify();

const fetcher = (...args) => fetch(...args).then((res) => res.json());
const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const RoutesWrapper = ({ children }) => {
    useEffect(() => {
        const handleRouteChange = (location) => {
            setActivity(location.pathname, ACTIVITY_TYPES.ROUTE);
        };
        const unlisten = history.listen(handleRouteChange);

        return () => {
            unlisten();
        };
    }, []);
    return <>{children}</>;
};
const App = () => {
    const { settings } = useSettings();
    const theme = createTheme({
        direction: settings.direction,
        //responsiveFontSizes: settings.responsiveFontSizes,
        theme: settings.theme,
    });

    const notistackRef = createRef();
    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };
    // useEffect(() => {
    //     const originalFetch = window.fetch;
    //     window.fetch = async function (...args) {
    //         const response = await originalFetch(...args);
    //         if (response.body) {
    //             const bodyText = await response.text();
    //         }
    //         return response;
    //     };
    //     return () => {
    //         window.fetch = originalFetch;
    //     };
    // }, []);

    return (
        <>
            <ThemeProvider theme={theme}>
                <StylesProvider jss={jss}>
                    {/* <MuiPickersUtilsProvider utils={MomentUtils}> */}
                    <SnackbarProvider
                        dense
                        maxSnack={3}
                        autoHideDuration={10000}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        ref={notistackRef}
                        action={(key) => (
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={onClickDismiss(key)}
                            >
                                <CloseRounded fontSize="small" />
                            </IconButton>
                        )}
                        TransitionComponent={Collapse}
                    >
                        <SWRConfig value={{ fetcher }}>
                            <Router history={history}>
                                <RoutesWrapper>
                                    <AuthProvider>
                                        <CustomerProvider>
                                            <GroupsProvider>
                                                <GlobalStyles />
                                                <ScrollReset />
                                                {renderRoutes(routes)}
                                            </GroupsProvider>
                                        </CustomerProvider>
                                    </AuthProvider>
                                </RoutesWrapper>
                            </Router>
                        </SWRConfig>
                    </SnackbarProvider>
                    {/* </MuiPickersUtilsProvider> */}
                </StylesProvider>
            </ThemeProvider>
        </>
    );
};

export default App;
